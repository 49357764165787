import Jumbotron from './Jumbotron.js';
import Logo from '../logo.png'
import './Home.css';

function Home() {
    return (
        <div className='Home'>
            <div className='flex flex-row w-full h-auto justify-center'>
                <img src={Logo} className="logo"></img>
            </div>
            <Jumbotron />
        </div>
    );
}

export default Home;
