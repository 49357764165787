import Img1 from '../resources/about/about-1.jpeg'
import Img2 from '../resources/about/about-2.jpeg'
import React from 'react';
import './About.css';

function About() {
    return (
        <div className="about w-full d-flex grid grid-cols-1 md:grid-cols-2 justify-between" id="about">
            <div className="d-flex text-xl h-auto flex-col align-middle">
                <div className="p-20">
                    <h1 class="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">We are the <span class="underline underline-offset-3 decoration-8 decoration-blue-400 decoration-blue-600">Leiden Debating Union</span></h1>
                    <p class="text-2xl font-normal text-gray-500 text-gray-400"> One of the most esteemed debating societies in the Netherlands, founded by and for students in Leiden (but not only!). We debate mostly in English, in the British Parliamentary (BP) format, but occasionally we try other formats as well (Eloquentia, American Parliament etc.).
                    </p>
                </div>

                <img class="image-about ml-10 mr-auto w-11/12" src={Img2}></img>
            </div>
            <div className="mt-4">
                <img src={Img1} className="image-about ml-10 mr-auto w-11/12"></img>
                <div className="p-20">
                    <h1 class="mb-4  font-extrabold text-gray-900 text-4xl md:text-5xl lg:text-6xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Our goal </span> is to create a safe & fun environment for everyone to enjoy debating</h1>
                    <p class="text-2xl font-normal text-gray-500 text-gray-400">Whether you are simply looking to improve your public speaking / argumentation skills, or you would like to participate in debating tournaments. We offer workshops on various debate topics, and we always organize fun socials to build a community and have fun!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
