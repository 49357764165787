import React from "react";
import TrophyImage from "../resources/successes/trophies.jpeg"

function Successes() {
    return (
        <div className="flex flex-col md:flex-row justify-between content-between">
            <div className="mx-[10%] md:mx-10 mt-10 mb-10 md:w-2/3">
                <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                    Our Successes
                </h1>
                <p className="text-2xl mr-0 md:mr-18 font-normal text-gray-500">
                    Our members have won various prestigious debating tournaments, both domestically and
                    internationally. We have been crowned champions in Berlin, Amsterdam, Oxford and Hong Kong. LDU has
                    won the European championships several times, most recently in 2020. Additionally, every year, we
                    run one of the largest debating tournaments in the Netherlands, Leiden Open.
                </p>
            </div>
            <div className="mx-[10%] md:mx-10 mt-10 mb-10 w-4/5 md:w-1/2">
                <div className="aspect-w-4 aspect-h-3">
                    <img
                        className="object-cover image-about "
                        src={TrophyImage}
                        alt="Trophies"
                    />
                </div>
            </div>
        </div>
    );

}

export default Successes;