import './App.css';
import Home from './components/Home.js';
import About from './components/About.js'
import { Navbar } from './components/Navbar.js'
import Footer from './components/Footer';
import Joining from './components/Joining.js';
import Successes from './components/Successes.js';

function App() {
    return (
        <div className="App">
            <Navbar />
            <header className="App-header">
                <Home />
            </header>            
            <About />
            <Joining />
            <Successes />
            <Footer />
        </div>
    );
}

export default App;
