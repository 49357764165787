function CardsDisplay(props) {
    return (
        <div className="flex flex-wrap justify-center space-x-0 md:space-x-4 mt-4">
            {props.children}
        </div>
    );
}

function Card(props) {
    return (
        <div className="bg-white rounded-lg shadow-lg p-6 w-full md:w-1/5 mb-4 md:mb-8">
            {props.children}
        </div>
    );
}

export {CardsDisplay, Card};